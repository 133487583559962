<template>
  <div class="d-flex align-items-center">
    <article-linked-in
        :article="article"
        :is-small="true"
        :link="article.pdf_url"
        :summary="article.summary"
        class="me-2"
    />
    <article-tweet
        :article="article"
        :is-small="true"
        :link="article.pdf_url"
        :summary="article.summary"
        class="me-2"
    />
    <div class="badge bg-green hoverable me-2" @click="goToSource">
      <link-icon size="15"/>
      Go to Source
    </div>
    <div class="badge bg-green hoverable" @click="mailToLink">
      <mail-icon class="custom-class" size="15"></mail-icon>
      Mail
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import ArticleLinkedIn from "@/components/articles/article-modal/ArticleLinkedIn";
import ArticleTweet from "@/components/articles/article-modal/ArticleTweet";
import { createAgenciesString } from "@/helpers/stringHelpers";
import { LinkIcon, MailIcon } from "vue-feather-icons";
import { mapGetters } from "vuex";

export default {
  name: "SocialButtonsSmall",
  components: {
    ArticleLinkedIn,
    ArticleTweet,
    LinkIcon,
    MailIcon,
  },

  props: {
    article: Object,
  },
  methods: {
    ...mapGetters("accounts", ["getCurrentUser"]),
    goToSource() {
      this.trackGoToSource()
      window.open(this.article.pdf_url, "_blank");
    },
    trackGoToSource() {
      this.$tracking.trackEvent("ViewArticleSource", {
        'id': this.article.id,
        'regalytics_alert_id': this.article.regalytics_alert_id,
        'title': this.article.title,
        'summary':this.article.summary
      });

    },
    mailTo(urlLink) {
      let article_summary = this.article.summary.substring(0, 300) + "...";
      let user = this.getCurrentUser();
      let publicLink = urlLink;
      let article_title = this.article.title;
      let agency_names = this.article.agencies;

      //To track mail article event to mixpanel
      this.$tracking.trackEvent("Mail Article", {
        "User Name": user.name,
        "Article Title": article_title,
        "Agencies": this.createAgenciesString(agency_names),
        "Share Url": publicLink,
      });

      window.open(
          "mailto:?subject= Saw this regulatory alert and thought of you...&body=Saw this regulatory alert courtesy of my RegAlytics subscription and thought of you!%0D%0A%0D%0ASincerely,%0D%0A" +
          user.name +
          "%0D%0A%0D%0A" +
          article_title +
          "%0D%0A" +
          this.createAgenciesString(agency_names) +
          "%0D%0A%0D%0A" +
          article_summary +
          "%0D%0A%0D%0A" +
          publicLink +
          "%0D%0A%0D%0ASign up for RegAlytics and make regulation easy!" +
          "%0D%0Ahttps://www.RegAlytics.ai",
          "_blank"
      );
    },
    createAgenciesString(allAgencies) {
      return createAgenciesString(allAgencies);
    },
    // Builds the short URL for use with tracking
    mailToLink() {
      axios
          .post(
              "/telemetry/create-sort-url",
              {
                regalytics_alert_id: this.article.regalytics_alert_id,
                campaign_type: "EMAIL",
              },
              {withCredentials: true}
          )
          .then((response) => {
            const shortUrl = response.data["short_url"];
            const finalUrl = `${window.location.origin}/link${shortUrl}`;
            this.mailTo(finalUrl);
          })
          .catch(() => {
            this.$store.dispatch("notifications/addNotification", {
              message:
                  "There was an error sharing this article! Please try again",
              type: "danger",
            });
          });
    },
  },
};
</script>

<style scoped>
.hoverable:hover {
  cursor: pointer;
  background-color: #55a8dc  !important;
}
</style>
